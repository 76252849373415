// React imports
import React from 'react';

// Theme and layout
import WithTheme from 'components/WithTheme';
import Layout from 'views/Layout';

const NotFound = ({ location }) => (
    <WithTheme>
        <Layout location={location}>404 Page Not Found</Layout>
    </WithTheme>
);

export default NotFound;
